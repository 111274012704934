.mygpt {
    max-width: 1200px;
    background-color: #2D3748;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  
    .mygpt-header {
      background-color: #4A5568;
      padding: 10px;
  
      h2 {
        font-size: 24px;
        font-weight: bold;
        color: #E5E7EB;
        margin: 0;
      }
    }
  
    .mygpt-body {
      padding: 20px;
  
      .mygpt-output {
        background-color: #4C566A;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 20px;
  
        p {
          font-size: 16px;
          color: #E5E7EB;
          margin: 0;
        }
      }
  
      .mygpt-input {
        display: flex;
  
        input {
          flex-grow: 1;
          border: none;
          background-color: #4A5568;
          border-radius: 10px;
          padding: 10px;
          font-size: 16px;
          color: #E5E7EB;
          outline: none;
  
          &::placeholder {
            color: #9CA3AF;
          }
        }
  
        button {
          background-color: #3B82F6;
          color: #FFFFFF;
          font-size: 16px;
          font-weight: bold;
          border: none;
          border-radius: 10px;
          padding: 10px 20px;
          margin-left: 10px;
          cursor: pointer;
  
          &:hover {
            background-color: #2563EB;
          }
        }
      }
    }
  }